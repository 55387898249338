<template>
  <v-container>
    <v-row align="center" justify="center" class="ma-1">
      <v-col cols="12" md="7" xl="6" class="elevation-1" style="background-color: white; border-radius: 8px">
        <h1 class="mt-2 text-center text-h5 appblue--text font-weight-medium">
          <span v-if="!productCode">Choose your subscription</span>
          <span v-else>Your Subscription</span>
        </h1>
        <div class="mt-1 mb-2 text-center p-text grey-navy--text font-weight-medium">
          Promo codes can be applied at the next step
        </div>

        <div class="d-flex justify-center align-baseline">
          <div class="font-weight-bold" :class="!threeMonthBilling ? 'red--text' : 'grey-navy--text'">Monthly</div>
          <v-switch
            v-model="threeMonthBilling"
            color="red"
            :ripple="false"
            class="ml-4 mr-3"
            @change="$store.dispatch('updatePreferredVariant', variantCode)"
          />
          <div class="text-center" :class="threeMonthBilling ? 'red--text' : 'grey-navy--text'">
            <div class="font-weight-bold">Quarterly</div>
          </div>
        </div>

        <div v-if="!productCode" class="d-flex flex-column justify-center mt-1">
          <simple-product-card
            code="jeff-club"
            :threeMonths="threeMonthBilling"
            color="#1F008E"
            bgColor="#F7F7F9"
            class="mx-2 mt-6 mx-xl-10"
            :recurringDiscountPercent="recurringDiscountPercent"
          />
          <simple-product-card
            v-if="!recurringDiscountPercent"
            code="jeff-club"
            :threeMonths="threeMonthBilling"
            color="#454545"
            bgColor="#F1F1F1"
            class="mx-2 mt-6 mx-xl-10"
            vitality
          />

          <simple-product-card
            code="1-on-1"
            :threeMonths="threeMonthBilling"
            color="#CA9E00"
            bgColor="#F5EFDA"
            class="mx-2 mt-6 mx-xl-10"
            :recurringDiscountPercent="recurringDiscountPercent"
          />

          <simple-product-card
            code="1-on-1-grad"
            :threeMonths="threeMonthBilling"
            color="#00864E"
            bgColor="#EBF9F3"
            class="mx-2 mt-6 mx-xl-10"
            :recurringDiscountPercent="recurringDiscountPercent"
          />
        </div>

        <div v-else>
          <simple-product-card
            :code="productCode"
            :threeMonths="threeMonthBilling"
            :show-learn-more="false"
            color="#1F008E"
            bgColor="#F7F7F9"
            class="mx-2 mt-6 mx-xl-10"
            :recurringDiscountPercent="recurringDiscountPercent"
          />
        </div>

        <need-more-time v-if="!productCode" />

        <div class="heading-3 font-weight-medium appblue--text pb-4 text-center mt-4">Helpful links</div>
        <div class="d-flex flex-wrap justify-center mb-4">
          <j-btn class="mr-4 mb-4" tertiary to="/rewards/points">
            Rewards
            <v-avatar tile size="16">
              <img :src="require('@/assets/images/rewards.svg')" max-height="16" max-width="16" />
            </v-avatar>
          </j-btn>
          <j-btn class="mr-4 mb-4" to="/account/billing" tertiary>
            BILLING <v-icon>mdi-credit-card-outline</v-icon>
          </j-btn>
          <j-btn class="mb-4" tertiary :to="supportChat ? `/chats/${this.supportChat.id}` : '/contact'">
            SUPPORT <v-icon>mdi-chat</v-icon>
          </j-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SIMPLE_ME_QUERY, ACCOUNT_QUERY } from "@/graphql/queries/account";
import SimpleProductCard from "@/components/start/SimpleProductCard";
import NeedMoreTime from "@/components/shared/sections/NeedMoreTime";
import Cookies from "js-cookie";

export default {
  name: "Pricing",
  components: {
    SimpleProductCard,
    NeedMoreTime,
  },
  data() {
    return {
      threeMonthBilling: false,
      // do we have a partner product variant code
      productCode: null,
      recurringDiscountPercent: null,
    };
  },
  created() {
    //this.recurringDiscountPercent = this.$store.getters.user?.userProfile?.externalCompany?.recurringDiscount?.percent;
    this.productCode = sessionStorage.getItem("product-code");

    if (!this.$store.getters.isLoggedIn) {
      return this.$router.push("/login");
    }
    const redirectProductCode = Cookies.get("redirectProductCode");
    if (redirectProductCode) {
      Cookies.remove("redirectProductCode");
      return this.$router.push(`/checkout/join?code=${redirectProductCode}`);
    }
  },
  computed: {
    variantCode() {
      return this.threeMonthBilling ? "quarterly" : "standard";
    },
  },
  apollo: {
    // we get fresh details for the user, to make sure that externalCompany is set if exists.
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      skip() {
        return !this.$store.getters.isLoggedIn;
      },
      result(response) {
        if (response.data && !response.loading) {
          this.$store.dispatch("updateUser", response.data.me);
          this.recurringDiscountPercent =
            this.$store.getters.user?.userProfile?.externalCompany?.recurringDiscount?.percent;
        }
      },
    },
    supportChat: {
      query: SIMPLE_ME_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          first: 1,
        };
      },
      update(data) {
        return data.me.userProfile.supportChat;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.right-arrow {
  transform: rotate(180deg);
}
</style>
