<template>
  <j-card rounded="md" :style="cardStyle">
    <v-skeleton-loader v-if="$apollo.loading" type="card" />
    <div v-else-if="product && variant && price" class="d-flex align-center">
      <div>
        <router-link :to="joinNowLink"><v-img :src="getImage" width="130" :aspect-ratio="1 / 1" cover /></router-link>
      </div>

      <div class="ml-4" style="width: 100%">
        <h3 class="appblue--text" :class="{ 'heading-6': $vuetify.breakpoint.smAndDown }">
          <router-link :to="joinNowLink" class="appblue--text font-weight-bold text-decoration-none">
            {{ product.title }}
          </router-link>
        </h3>
        <div v-if="vitality" class="sm-text appblue--text font-weight-medium">with Discovery Vitality</div>
        <div
          class="appblue--text heading-3 font-weight-regular mt-1"
          :class="{ 'p-text font-weight-medium': $vuetify.breakpoint.smAndDown }"
        >
          R {{ parseInt(price) }}

          <span>
            <span v-if="!threeMonths"> / month</span>
            <span v-else> / 3 months</span>
          </span>

          <div v-if="recurringDiscountPercent" class="text-grey--text p-text font-weight-medium text-decoration-none">
            You save {{ roundedRecurringDiscountPercent }}%
          </div>

          <div v-if="variant.shortDescription" class="mt-1 mb-2 text-left p-text grey-navy--text font-weight-medium">
            {{ variant.shortDescription }}
          </div>
        </div>

        <div :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }" class="mt-2 mb-2">
          <j-btn v-if="buttonClick" @click="buttonClick && buttonClick()" narrow>{{ buttonText }}</j-btn>
          <j-btn v-else :to="joinNowLink" narrow>{{ buttonText }}</j-btn>
        </div>
      </div>

      <div class="text-end mr-4" :class="{ 'd-none': $vuetify.breakpoint.smAndDown }">
        <div class="mt-2">
          <j-btn v-if="buttonClick" @click="buttonClick && buttonClick()" width="120px">{{ buttonText }}</j-btn>
          <j-btn v-else width="120px" :to="joinNowLink">{{ buttonText }}</j-btn>
        </div>
        <div v-if="showLearnMore" class="mt-2 text-center">
          <a
            :href="learnMoreLink"
            target="_blank"
            class="text-grey--text p-text font-weight-medium text-decoration-none"
            >Learn more</a
          >
        </div>
      </div>
    </div>
  </j-card>
</template>

<script>
import { START_PRODUCTS_QUERY } from "@/graphql/queries/start";

export default {
  name: "SimpleProductCard",
  props: {
    code: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    threeMonths: {
      type: Boolean,
      default: false,
    },
    vitality: {
      type: Boolean,
      default: false,
    },
    showLearnMore: {
      type: Boolean,
      default: true,
    },
    recurringDiscountPercent: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      default: "Continue",
    },
    buttonClick: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      productCode: null,
      // WATCH OUT.
      // These hard coded here and in Checkout and must match up what is set in the DB under RecurringDiscounts
      discoveryDiscountPercent: 25,
      discoveryProductImage: "https://storage.googleapis.com/master_site/settings/clubvitalityproduct.png",
    };
  },
  created() {
    this.productCode = sessionStorage.getItem("product-code");
  },
  computed: {
    roundedRecurringDiscountPercent() {
      return Math.round(this.recurringDiscountPercent);
    },
    cardStyle() {
      return `border: 2px solid ${this.color}; background-color: ${this.bgColor} `;
    },
    learnMoreLink() {
      if (this.code.includes("jeff-club")) {
        return "https://www.jeff.fitness/jeff-club";
      }
      return `https://www.jeff.fitness/personal-coaching`;
    },
    joinNowLink() {
      let extra = "";
      if (this.vitality) {
        extra = `?vitality=true`;
      }
      return `/checkout/${this.code}${extra}`;
    },
    getImage() {
      if (this.vitality) {
        return this.discoveryProductImage;
      }
      return this.product.image;
    },
    variant() {
      return this.product?.productvariantSet?.edges
        .map((variant) => variant.node)
        ?.filter((variant) => variant.code == `${this.variantCode}-${this.code}`)[0];
    },
    price() {
      if (this.recurringDiscountPercent) {
        return (
          this.variant?.productvariantpriceSet?.filter((set) => set.currency == "ZAR")[0].price *
          ((100 - this.recurringDiscountPercent) / 100)
        );
      }

      if (this.vitality) {
        return (
          this.variant?.productvariantpriceSet?.filter((set) => set.currency == "ZAR")[0].price *
          ((100 - this.discoveryDiscountPercent) / 100)
        );
      }
      return this.variant?.productvariantpriceSet?.filter((set) => set.currency == "ZAR")[0].price;
    },
    variantCode() {
      return this.threeMonths ? "quarterly" : "standard";
    },
  },
  apollo: {
    product: {
      query: START_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.code,
        };
      },
      update(data) {
        return data.billingProducts?.edges?.map((product) => product.node)[0];
      },
    },
  },
};
</script>
