import gql from "graphql-tag";

export const START_PRODUCTS_QUERY = gql`
  query products($code: String!) {
    billingProducts(code: $code) {
      edges {
        node {
          id
          title
          code
          image
          description
          shortDescription
          productvariantSet {
            edges {
              node {
                id
                code
                title
                firstMonthDiscountedAmount
                shortDescription
                productvariantpriceSet {
                  id
                  currency
                  price
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RECOMMENDED_JOURNEY_PLAN_QUERY = gql`
  query recommendedJourneyPlan($goals: [String]!, $fitnessLevel: String!, $desiredWeightLoss: Decimal!) {
    recommendedJourneyPlan(goals: $goals, fitnessLevel: $fitnessLevel, desiredWeightLoss: $desiredWeightLoss) {
      id
      title
      image
    }
  }
`;

export const DEAL_OWNER_QUERY = gql`
  query dealOwner($code: String!) {
    dealOwner(code: $code) {
      id
      code
      image
    }
  }
`;
