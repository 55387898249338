<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12">
        <j-banner-container
          header="Continue Your Journey"
          md="5"
          :gradient="['green-background', 'yellow-background']"
          class="my-2"
        >
          <div class="text-left">
            <div v-if="subscription && subscription.transitionProductVariant">
              <j-alert type="info" class="ma-4">
                You can't move plans right now. It looks like a new one may already be set. If you have any questions
                you can contact us on WhatsApp to chat.
              </j-alert>
            </div>

            <div v-else>
              <div v-if="success">
                <j-alert type="success" class="ma-4">
                  Success! Your new plan will start as soon as your current program ends. You can close this page now.
                </j-alert>
              </div>

              <div v-else>
                <p class="mt-4 text-center p-text">
                  Stay on track with a JEFF Membership. You can quickly and easily continue with one of the products
                  below.
                </p>
                <simple-product-card
                  code="jeff-club"
                  :threeMonths="false"
                  color="#454545"
                  bgColor="#F1F1F1"
                  class="mx-2 mt-6 mx-xl-10"
                  :buttonClick="() => buttonClick('standard-jeff-club')"
                />

                <simple-product-card
                  code="1-on-1"
                  :threeMonths="false"
                  color="#CA9E00"
                  bgColor="#F5EFDA"
                  class="mx-2 mt-6 mx-xl-10"
                  :buttonClick="() => buttonClick('standard-1-on-1')"
                />
              </div>
            </div>
          </div>

          <need-more-time />
        </j-banner-container>
      </v-col>
    </v-row>
    <v-dialog v-model="showConfirmDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <j-card rounded="lg" class="pb-4">
        <v-toolbar class="mb-6">
          <v-toolbar-title> Confirm Your Move</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showConfirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p>You can always reach us on WhatsApp if you want to change anything after you've confirmed.</p>
          <j-btn @click="doTransition">Confirm</j-btn>
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import SimpleProductCard from "@/components/start/SimpleProductCard";
import NeedMoreTime from "@/components/shared/sections/NeedMoreTime";
import { TRANSITION_SUBSCRIPTION_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "BillingTransition",
  components: {
    SimpleProductCard,
    NeedMoreTime,
  },
  data() {
    return {
      showConfirmDialog: false,
      selectedCode: null,
      success: false,
      subscription: null,
    };
  },
  methods: {
    buttonClick(type) {
      this.showConfirmDialog = true;
      this.selectedCode = type;
    },

    async doTransition() {
      this.showConfirmDialog = false;

      const result = await this.$apollo.mutate({
        mutation: TRANSITION_SUBSCRIPTION_MUTATION,
        variables: {
          productVariantCode: this.selectedCode,
          endDate: this.subscription.endDate ? this.$moment(this.subscription.endDate).format("YYYY-MM-DD") : null,
        },
      });
      this.success = result.data.transitionSubscriptionMutation.success;
    },
  },

  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          this.subscription = response.data.subscription;
        }
      },
    },
  },
};
</script>
